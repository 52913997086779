import type { CorePageProps } from "@/types/page";
import type { ReactElement } from "react";
import type { GetStaticProps } from "next";
import { BaseLayout } from "@/layouts/base-layout";
import { dehydrate, QueryClient } from "@tanstack/react-query";
import { prefetchModules } from "@/lib/contentful/modules/prefetch-modules";
import { fetchMicrocopySets } from "@/lib/contentful/microcopy/fetch-microcopy-sets";
import { prefetchFooter } from "@/lib/contentful/footer/prefetch-footer";
import { prefetchMainNavigation } from "@/lib/contentful/main-navigation/prefetch-main-navigation";
import type {
    SiteSettingsCollectionQueryVariables,
    SiteSettingsWithNotFoundPageModulesQueryVariables,
} from "@/lib/contentful/site-settings/__generated/site-settings.contentful.generated";
import {
    useSiteSettingsCollectionQuery,
    useSiteSettingsWithNotFoundPageModulesQuery,
} from "@/lib/contentful/site-settings/__generated/site-settings.contentful.generated";
import { useRouter } from "next/router";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import { ModuleRenderer } from "@/lib/contentful/modules/ModuleRenderer";
import { Box } from "@project/ui";
import { prefetchPartnerSet } from "@/lib/contentful/partner-set/prefetch-partner-set";

type NotFoundPageProps = Omit<CorePageProps, "pageId" | "pagePresentation">;

const NotFoundPage = ({ siteSettingsId }: NotFoundPageProps) => {
    const { locale, isPreview } = useRouter();

    const { data, isLoading } = useSiteSettingsWithNotFoundPageModulesQuery({
        locale: locale!,
        preview: Boolean(isPreview),
        id: siteSettingsId,
    });

    const pageData = useContentfulLiveUpdates(data?.siteSettings, { locale });

    if (isLoading || !pageData) {
        return null;
    }

    return (
        <Box pt={{ base: "navHeightMobile", md: 0 }}>
            {data?.siteSettings?.notFoundPageModulesCollection?.items.map(
                (module) =>
                    module && <ModuleRenderer componentProps={module} key={module?.sys?.id} />
            )}
        </Box>
    );
};

NotFoundPage.getLayout = (page: ReactElement) => <BaseLayout>{page}</BaseLayout>;

export const getStaticProps: GetStaticProps<NotFoundPageProps> = async ({ locale, draftMode }) => {
    const queryClient = new QueryClient();

    const siteSettingsQueryVariables: SiteSettingsCollectionQueryVariables = {
        locale: locale!,
        preview: Boolean(draftMode),
    };

    const siteSettingsData = await useSiteSettingsCollectionQuery.fetcher(
        siteSettingsQueryVariables
    )();

    const siteSettings = siteSettingsData.siteSettingsCollection!.items[0];

    const siteSettingsVariables: SiteSettingsWithNotFoundPageModulesQueryVariables = {
        locale: locale!,
        preview: Boolean(draftMode),
        id: siteSettings!.sys.id,
    };

    const pageData =
        await useSiteSettingsWithNotFoundPageModulesQuery.fetcher(siteSettingsVariables)();

    await queryClient.prefetchQuery({
        queryKey: useSiteSettingsWithNotFoundPageModulesQuery.getKey(siteSettingsVariables),
        queryFn: useSiteSettingsWithNotFoundPageModulesQuery.fetcher(siteSettingsVariables),
    });

    const pageModules = pageData?.siteSettings?.notFoundPageModulesCollection?.items ?? [];

    await prefetchModules({
        queryClient,
        inputArray: pageModules,
        locale: locale!,
        preview: Boolean(draftMode),
    });

    const microcopySets = await fetchMicrocopySets({
        locale: locale!,
        preview: Boolean(draftMode),
    });

    const { footerId, footerPartnerSetId } = await prefetchFooter({
        queryClient,
        locale: locale!,
        preview: Boolean(draftMode),
    });

    const { mainNavigationId, hasLiveTicker } = await prefetchMainNavigation({
        queryClient,
        locale: locale!,
        preview: Boolean(draftMode),
    });

    const { partnerSetId } = await prefetchPartnerSet({
        partnerSetId: footerPartnerSetId,
        queryClient,
        locale: locale!,
        preview: Boolean(draftMode),
    });

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            siteSettingsId: siteSettings!.sys.id,
            footerId: footerId!,
            mainNavigationId: mainNavigationId!,
            partnerSetId,
            microcopySets,
            pageType: "PageCategory", // "hacky" way to get the correct logo color
            hasLiveTicker,
            pageContentTags: [],
        },
        revalidate: 60,
    };
};

export default NotFoundPage;
